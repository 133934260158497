import LocationsEdit from './components/Edit'
import LocationsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemLocations = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Booking Links"
        editTitle="Booking Link "
        match={match}
        section={section}
        innerSection={'booking_links'}
        Edit={LocationsEdit}
        All={LocationsAll}
    />
)

export default SystemLocations;