import { connect } from 'react-redux'
import IntuideskSection from "components/markup/layout/IntuideskSection";

import Forms from './Forms'
import Tags from './Tags'
import Locations from './Locations'
import ContactTypes from './ContactTypes'
import MatterDocuments from './MatterDocuments'
import SigningTemplates from './SigningTemplates'
import EventTemplates from './EventTemplates'
import Workflows from './Workflows'
// import Wikis from './Wikis'
import CustomFields from './CustomFields'
import CustomDates from './CustomDates'
import TimeEntries from './TimeEntries'
import LocationTypes from './LocationTypes'
import EventTypes from './EventTypes'
import EventOutcomes from './EventOutcomes'
import Pipelines from './Pipelines'
import Departments from './Departments'
import WordDocuments from './WordDocuments'
import BookingLinks from './BookingLinks'

const SystemView = ({match, selected_division}) => (
    <IntuideskSection 
        title={`System ${selected_division ? ' - ' + selected_division.name : ''}`}
        section="system"
        defaultRoute="workflows"
        match={match}
        pages={[
            { value: 'workflows',         icon: 'fas fa-route',             name: 'Flows',          Component: Workflows },
            { value: 'contact_types',     icon: 'fas fa-id-card',           name: 'Contacts',       Component: ContactTypes },
            { value: 'forms',             icon: 'fas fa-edit',              name: 'Forms',          Component: Forms },
            { value: 'tags',              icon: 'fas fa-tags',              name: 'Tags',           Component: Tags },
            { value: 'locations',         icon: 'fas fa-building',          name: 'Locations',      Component: Locations   },
            { value: 'location_types',    icon: 'fas fa-building',          name: 'Location Types', Component: LocationTypes, hidden: true   },
            { value: 'matter_documents',  icon: 'fas fa-folder-plus',       name: 'Docs',           Component: MatterDocuments   },
            { value: 'signing_templates', icon: 'fas fa-signature',         name: 'Sign',           Component: SigningTemplates },
            { value: 'word_documents',    icon: 'fas fa-file',              name: 'Templates',      Component: WordDocuments },
            { value: 'booking_links',     icon: 'fas fa-calendar',          name: 'Bookings',         Component: BookingLinks },
            { value: 'event_templates',   icon: 'fas fa-calendar',          name: 'Events',         Component: EventTemplates },
            { value: 'event_types',       icon: 'fas fa-calendar',          name: 'Event Types',    Component: EventTypes, hidden: true },
            { value: 'event_outcomes',    icon: 'fas fa-calendar',          name: 'Event Outcomes', Component: EventOutcomes, hidden: true },
            { value: 'time_entries',      icon: 'fa-solid fa-clock',        name: 'Time',           Component: TimeEntries },
            { value: 'pipelines',         icon: 'fa-solid fa-database',     name: 'Pipelines',      Component: Pipelines },
            { value: 'custom_fields',     icon: 'fa-solid fa-database',     name: 'Fields',         Component: CustomFields },
            { value: 'custom_dates',      icon: 'fa-solid fa-map-pin',      name: 'Custom Dates',   Component: CustomDates },
            // { value: 'wikis',             icon: 'fas fa-info-circle',       name: 'Wikis',          Component: Wikis },
            { value: 'departments',       icon: 'fas fa-home',              name: 'Departments',    Component: Departments },
        ]}
    />
)


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemView);