import { useState, useCallback, memo, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom';

import InternalSidebar from 'components/markup/layout/InternalSidebar'

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import NotFound from 'components/markup/layout/NotFound'

const IntuideskSectionWrapper = ({match, title, editTitle, innerSection, section, editLink, Edit, All, params}) => {
    const [lastParam, setLastParam] = useState(match.params.nav2 ? match.params.nav2 : 'all')
    const [tab, setTab] = useState(match.params.nav2 ? match.params.nav2 : 'all')
    const [redirect, setRedirect] = useState(false);
    if(!params) params = {}

    const onSetTab = useCallback((tab) => {
        if(tab === 'create' && editLink) return setRedirect(editLink)
        setTab(tab)
        let url = `/${section}/${innerSection.toLowerCase().replace(/ /g, '_')}`
        if(tab !== 'all') url += '/' + tab.toLowerCase()
        window.history.pushState('data', innerSection, url);
        window.scrollTo(0, 0);
    }, [section, innerSection, editLink])

    useEffect(() => {
        if(tab === 'create' && editLink) return setRedirect(editLink)
    }, [tab, editLink])

    useEffect(() => {
        const mParam = match.params.nav2 ? match.params.nav2 : null;
        if(lastParam === mParam) return;
        if(mParam && mParam !== tab) onSetTab(mParam)
        setLastParam(mParam)
    }, [tab, match.params.nav2, onSetTab, lastParam])

    if(redirect) return <Redirect to={redirect} />

    return (
        
        <InternalSidebar
            title={title}
            tab={tab}
            setTab={onSetTab}
            tabs={[
                { value: 'all', name: 'View All' },
                { value: 'create', name: 'Create' },
            ]}
        >
            {tab === 'create' || tab !== 'all' ? (
                <>
                    <TextButtonRow 
                        title={(
                            <span>
                                {tab === 'create' ? 'CREATE ' : 'EDIT '}{' '}
                                <i className="fas fa-chevron-right text-muted mr--3" /> <i className="fas fa-chevron-right text-muted " />{' '}
                                {editTitle}
                            </span>
                        )} 
                        button={editLink ? (
                            <Link 
                                to={editLink}
                                className="btn btn-outline-info"
                            >
                                <i className="fas fa-arrow-left mr-2 " /> View All
                            </Link>
                        ) : (
                            <button 
                                onClick={() => onSetTab('all')} 
                                className="btn btn-outline-info"
                            >
                                <i className="fas fa-arrow-left mr-2 " /> View All
                            </button>
                        )}
                    />
                    {Edit ? <Edit _id={tab} {...params} setTab={setTab} />  : ''}
                </>
            )  : tab === 'all' ? (
                <All 
                    {...params}
                    setTab={setTab}
                />
            ) : (
                <NotFound />
            )}
        </InternalSidebar>
    )

}

export default memo(IntuideskSectionWrapper);