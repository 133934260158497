import { useCallback, useState } from 'react';
import { connect } from 'react-redux';


import ReactSelect from 'components/functional/inputs/ReactSelect';

import CallRoles from './CallRoles';
import CallUsers from './CallUsers';
import CallPhones from './CallPhones';
import Gather from './Gather';
import OutboundDial from './OutboundDial';
import RouteToQueue from './RouteToQueue';
import VoicemailQueue from './VoicemailQueue';
import VoicemailUsers from './VoicemailUsers';
import SequentialDial from './SequentialDial';

const baseState = {

    // gather, call_users, call_roles, outbound_dial, sequential_dial, voicemail_users, voicemail_queue, route_to_queue,
    type                              : '',
    
    users_dial                        : [],
    users_voicemail                   : [],

    roles_dial                        : [],
    roles_voicemail                   : [],

    call_queue_dial                   : '',
    call_queue_voicemail              : '',

    // used for outbound_dial
    phone_number                      : '',

    // used for gather and optionally hotline and callback
    audio_clip                        : '',
    words                             : '',

    // used for gather
    on_press_1                        : '',
    on_press_2                        : '',
    on_press_3                        : '',
    on_press_4                        : '',
    on_press_5                        : '',
    on_press_6                        : '',
    on_press_7                        : '',
    on_press_8                        : '',
    on_press_9                        : '',
    on_press_star                     : '',

    // voicemail_users, voicemail_roles, voicemail_queue, dial_number, route_to_queue
    no_answer_type                    : 'voicemail_queue',

    no_answer_users_voicemail         : [],
    no_answer_roles_voicemail         : [],
    no_answer_call_queue_voicemail    : '',

    no_answer_call_queue_dial         : '',

    no_answer_phone_number            : '',
   
}

const SystemCallFlowEdit = ({callFlow, workflows, onTasksChange, selected_division}) => {

    const [task, setTask] = useState(callFlow.tasks[0] ? callFlow.tasks[0] : baseState)

    const onSetTask = useCallback((field, val) => {
        const _task = JSON.parse(JSON.stringify(task));
        _task[field] = val;
        setTask(_task)
        onTasksChange(_task)
    }, [task, onTasksChange])

    const onUpload = (documents) => {
        const doc = documents ? documents[documents.length - 1 ] : ''
        if(!doc) return;

        onSetTask('audio_clip', doc._id)
    }

    return (
        <div>

            <div className='bg-secondary p-3 border'>
                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info mr-2" /> Tasks are the actions performed when this call route is triggered. Think what do I want to happen when someone ends up here?</p>
            </div>

            <ReactSelect 
                formGroup={true}
                title="Task Type"
                value={task.type}
                onChange={(obj) => onSetTask('type', obj.value)}
                options={[
                    { value: 'gather', label: 'Press A Key After Audio Is Played' },

                    { value: 'call_users', label: 'Dial Specific Users' },
                    { value: 'call_phones', label: 'Dial Specific Users Cell Phone' },
                    { value: 'call_roles', label: 'Dial Specific Roles' },

                    { value: 'outbound_dial', label: 'Dial Specific Number' },
                    { value: 'sequential_dial', label: 'Sequential Dial' },

                    { value: 'voicemail_users', label: 'Leave A Voicemail - Users' },
                    { value: 'voicemail_queue', label: 'Leave A Voicemail - Queue' },

                    { value: 'route_to_queue', label: 'Move To Queue' },
                ]}
            />

            {task.type === 'gather' ? (
                <Gather 
                    task={task}
                    onSetTask={onSetTask}
                    onUpload={onUpload}
                />
            ) : task.type === 'call_users' ? (
                <CallUsers 
                    task={task}
                    onSetTask={onSetTask}
                />
            )  : task.type === 'call_roles' ? (
                <CallRoles 
                    task={task}
                    onSetTask={onSetTask}
                    workflows={workflows}
                />
            ) : task.type === 'outbound_dial' ? (
                <OutboundDial 
                    task={task}
                    onSetTask={onSetTask}
                />
            ) : task.type === 'sequential_dial' ? (
                <SequentialDial 
                    task={task}
                    onSetTask={onSetTask}
                />
            ) : task.type === 'voicemail_users' ? (
                <VoicemailUsers 
                    task={task}
                    onSetTask={onSetTask}
                />
            ) : task.type === 'voicemail_queue' ? (
                <VoicemailQueue 
                    task={task}
                    onSetTask={onSetTask}
                />
            ) : task.type === 'route_to_queue' ? (
                <RouteToQueue 
                    task={task}
                    onSetTask={onSetTask}
                />
            ) : task.type === 'call_phones' ? (
                <CallPhones 
                    task={task}
                    onSetTask={onSetTask}
                />
            )  : ''}



        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);