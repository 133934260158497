import { Input, FormGroup, Badge, Row, Col } from "reactstrap";
import { useCallback, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import api from 'api'
import Modalify from 'components/functional/modals/Modalify'


import ReactSelect from 'components/functional/inputs/ReactSelect'
import SearchCollections from "components/system/Search/Collections";
import ObjectFinder from 'components/system/Objects/Finder';

import DayAndTimeRange from 'components/settings/DayAndTimeRange';
import TimeZone from 'components/settings/TimeZone';

import { toggleStandardLoader } from "store/functions/system/system";
              

const minutesInHour = 60;

const baseState = {
    name : '',
    slug : '',
    
    event_template : '',

    create_lead: false,
    lead_source: '',
    
    create_matter: false,
    matter: {
        workflow: '',
        workflow_role: '',
        workflow_contact: '',
    },

    participants: [],

    availability: {
        duration_minutes  : 30,
        interval_minutes  : 30,
        round_to          : 15,

        availability_rules: {
            availability_method : 'collective',
            buffer: {
                before          : 0,
                after          : 0,
            },
            times_open: {
                mon: { start: 32400, end: 64800 },
                tue: { start: 32400, end: 64800 },
                wed: { start: 32400, end: 64800 },
                thu: { start: 32400, end: 64800 },
                fri: { start: 32400, end: 64800 },
                sat: { start: 0, end: 0 },
                sun: { start: 0, end: 0 },
            }
        },
    },

    event_booking: {
        title         : '',
        description   : '',
        timezone      : 'America/New_York',
        booking_type      : 'booking',
        conferencing: {},
        disable_emails      : false
    },

    scheduler: {
        available_days_in_future      : 30,
        min_booking_notice  : 360,
    },

}

const SystemBookingLinkEdit = ({_id, onSaved, showModal, toggleModal, selected_division, setTab}) => {

    const [bookingLink, setBookingLink] = useState(baseState)
    const [redirect, setRedirect] = useState(null)
    const [err, setErr] = useState(null)

    const isRoundRobin = bookingLink.availability.availability_rules.availability_method === 'collective' ||
    bookingLink.availability.availability_rules.availability_method === 'user_admin' ? false : true

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setBookingLink(baseState);
        const user = await api.booking_links.findById(_id)
        if(!user.data) return setErr(true);
        setBookingLink(user.data)
    }, [_id])

    const onNestedInputChange = useCallback(( field, value) => {
        const newbookingLink = JSON.parse(JSON.stringify(bookingLink))
        const path = field.split('.')

        let currentLevel = newbookingLink;

        for (let i = 0; i < path.length - 1; i++) {
            const prop = path[i];
            if (!currentLevel[prop]) {
                currentLevel[prop] = {};
            }
            currentLevel = currentLevel[prop];
        }
        currentLevel[path[path.length - 1]] = value;
    
        setBookingLink(newbookingLink);
    }, [bookingLink])

    const onParticipantChange = useCallback((user) => {
        const newbookingLink = JSON.parse(JSON.stringify(bookingLink))

        if(isRoundRobin) {
            const index = newbookingLink.participants.findIndex(f => f === user)

            if(index === -1) {
                newbookingLink.participants.push(user)
            } else {
                newbookingLink.participants = newbookingLink.participants.filter(u => u !== user)
            }
        } else {
            newbookingLink.participants = [user]
        }

        setBookingLink(newbookingLink);
    }, [bookingLink, isRoundRobin])
    
  
    const onSave = useCallback(async () => {

        if(!isRoundRobin && bookingLink.participants.length > 1) {
            bookingLink.participants = [bookingLink.participants[0]]
        }

        if(!bookingLink.name) return setErr([`A booking link must have a name`])
        if(!bookingLink.slug) return setErr([`A booking link must have a unique slug`])
        if(!bookingLink.event_booking.title) return setErr([`A booking link must a client facing event name`])
        if(!bookingLink.participants.length) return setErr([`A booking link must have at least 1 associated users`])
        if(isRoundRobin && bookingLink.participants.length < 2) return setErr([`At least 2 participants must be set when creating a round robin booking link.`])
        
        let saved;

        toggleStandardLoader(true)
        if(bookingLink._id) {
            saved = await api.booking_links.update(bookingLink._id, { ...bookingLink, division: selected_division._id });
        } else {
            saved = await api.booking_links.create({ ...bookingLink, division: selected_division._id });
        }
        toggleStandardLoader(false)

        if(saved.message && saved.message[0] && saved.message[0].error && saved.message[0].error.type === 'authentication_error') {
            return setErr([`Authentication error, this means one or more users on the event must resync there email or an invalid configuration was found. Provider message: ${saved.message[0].error.message}`])
        }

        if(!saved.success) return setErr(saved.message)
        if(onSaved) onSaved(saved.data)

        if(toggleModal) {
            toggleModal();
        } else {
            setRedirect('/system/booking_links')
            setTab('all')
        }

    }, [isRoundRobin, bookingLink, onSaved, toggleModal, selected_division._id, setTab])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setBookingLink(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    if(redirect) return <Redirect to={redirect} />

    console.log(bookingLink)

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={bookingLink && bookingLink._id ? "Update Booking Link" : "Create Booking Link"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!bookingLink ? [] :  [
                (
                    <div>

                        <h2>Internal Settings</h2>

                        <FormGroup>
                            <label className="form-control-label">Internal Name (Shows Only Within IntuiDesk)</label>
                            <Input 
                                type="text"
                                value={bookingLink.name || ''}
                                onChange={e => onNestedInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Slug (The URL this link will be accessed at, must be unique)</label>
                            <Input 
                                type="text"
                                value={bookingLink.slug || ''}
                                onChange={e => onNestedInputChange('slug', e.target.value)}
                            />
                        </FormGroup>

                        <ReactSelect 
                            formGroup={true}
                            title="Booking Type"
                            value={bookingLink.availability.availability_rules.availability_method}
                            onChange={(obj) => onNestedInputChange('availability.availability_rules.availability_method', obj.value)}
                            options={[
                                { value: 'collective', label: 'Single Person' },
                                { value: 'max-availability', label: 'Round Robin (Max Fairness)' },
                                { value: 'max-fairness', label: 'Round Robin (Max Availability)' },
                            ]}
                        />
                        
                    </div>
                ),
                (
                    <div>

                        <h2>Client Facing Settings</h2>

                        <FormGroup>
                            <label className="form-control-label">Event Name (Shows In Client's Calendar)</label>
                            <Input 
                                type="text"
                                value={bookingLink.event_booking.title || ''}
                                onChange={e => onNestedInputChange('event_booking.title', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Optional Event Description (Shows In Client's Calendar)</label>
                            <Input 
                                type="text"
                                value={bookingLink.event_booking.description || ''}
                                onChange={e => onNestedInputChange('event_booking.description', e.target.value)}
                            />
                        </FormGroup>

                    </div>
                ),
                (
                    <div>

                        <h2>Associated User{isRoundRobin ? 's' : ''} </h2>

                        <SearchCollections
                            collection={'users'}
                            title="Select User"
                            placeholder=""
                            hideSelected={true}
                            value={''}
                            onChange={(obj) => onParticipantChange(obj.value)}
                            filter={{
                                division: selected_division._id
                            }}
                        /> 

                        {bookingLink.participants.map((u, i) => (
                            !isRoundRobin && i > 0 ? '' :
                            <Badge key={u} color="success">
                                <ObjectFinder collection="users" _id={u} /> 
                                <i className="fas fa-times pl-4 cursor-pointer" onClick={() => onParticipantChange(u)} />
                            </Badge>
                        ))}

                    </div>
                ),
                (
                    <div>

                        <h2>Day & Time Settings</h2>
                        <p className="text-sm mt--2">These settings control the master hours that people can use to book an appointment with this link</p>

                        <DayAndTimeRange 
                            times={bookingLink.availability.availability_rules.times_open}
                            setTimes={(value) => onNestedInputChange('bookingLink.availability.availability_rules.times_open', value)}
                        />    

                    </div>
                ),
                (
                    <div>

                        <h2>Event Time Settings</h2>

                        <TimeZone 
                            timezone={bookingLink.event_booking.timezone}
                            setTimezone={(value) => onNestedInputChange('event_booking.timezone', value)}
                        />    

                        <ReactSelect 
                            formGroup={true}
                            title="Event Duration"
                            description="The total number of minutes the event should last"
                            value={bookingLink.availability.duration_minutes}
                            onChange={obj => onNestedInputChange('availability.duration_minutes', obj.value)}
                            options={[
                                { value: 10, label: '10 Minutes' },
                                { value: 15, label: '15 Minutes' },
                                { value: 20, label: '20 Minutes' },
                                { value: 30, label: '30 Minutes' },
                                { value: 45, label: '45 Minutes' },
                                { value: 60, label: '1 Hour' },
                                { value: 75, label: '1 Hour 15 Minutes' },
                                { value: 90, label: '1 Hour 30 Minutes' },
                                { value: 105, label: '1 Hour 45 Minutes' },
                                { value: 120, label: '2 Hours' },
                                { value: 135, label: '2 Hours 15 Minutes' },
                                { value: 150, label: '2 Hours 30 Minutes' },
                                { value: 165, label: '2 Hours 45 Minutes' },
                                { value: 180, label: '3 Hours' },
                                { value: 210, label: '3 Hours 30 Minutes' },
                                { value: 240, label: '4 Hours' },
                            ]}
                        /> 

                        <Row>
                            <Col md={6}>
                                <ReactSelect 
                                    formGroup={true}
                                    title="Open Time Before Event"
                                    description="This controls how much time must be open before an event before it can be scheduled. For instance if you set this to 30 minutes and an event ends in a user's calendar at 9:30 AM no one will be able to book them until 10:00AM with this link"
                                    value={bookingLink.availability.availability_rules.buffer.before}
                                    onChange={(obj) => onNestedInputChange('availability.availability_rules.buffer.before', obj.value)}
                                    options={[
                                        { value: 0, label: 'No Buffer' },
                                        { value: 15, label: '15 Minutes' },
                                        { value: 30, label: '30 Minutes' },
                                        { value: 45, label: '45 Minutes' },
                                        { value: 60, label: '60 Minutes' },
                                    ]}
                                />
                            </Col>
                            <Col md={6}>
                                <ReactSelect 
                                    formGroup={true}
                                    title="Open Time After Event"
                                    description="Similar to 'Buffer Before Event' however control the time to book after an event. For instance event coming at 10:00 AM in a users calendar and you set this for 15 Minutes no one will be able to book them with this link until 10:15AM"
                                    value={bookingLink.availability.availability_rules.buffer.after}
                                    onChange={(obj) => onNestedInputChange('availability.availability_rules.buffer.after', obj.value)}
                                    options={[
                                        { value: 0, label: 'No Buffer' },
                                        { value: 15, label: '15 Minutes' },
                                        { value: 30, label: '30 Minutes' },
                                        { value: 45, label: '45 Minutes' },
                                        { value: 60, label: '60 Minutes' },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                ),
               
                (
                    <div>

                        <h2>Booking Parameters</h2>

                        <ReactSelect 
                            formGroup={true}
                            title="How many days in the future can someone book this event?"
                            value={bookingLink.scheduler.available_days_in_future}
                            onChange={(obj) => onNestedInputChange('scheduler.available_days_in_future', obj.value)}
                            options={[
                                { value: '', label: 'Indefinitely' },
                                { value: 1, label: '1 Day' },
                                { value: 2, label: '2 Days' },
                                { value: 3, label: '3 Days' },
                                { value: 4, label: '4 Days' },
                                { value: 5, label: '5 Days' },
                                { value: 6, label: '6 Days' },
                                { value: 7, label: '7 Days' },
                                { value: 10, label: '10 Days' },
                                { value: 14, label: '14 Days' },
                                { value: 21, label: '21 Days' },
                                { value: 28, label: '28 Days' },
                                { value: 30, label: '30 Days' },
                                { value: 45, label: '45 Days' },
                                { value: 60, label: '60 Days' },
                                { value: 75, label: '75 Days' },
                                { value: 90, label: '90 Days' },
                                { value: 120, label: '120 Days' },
                                { value: 150, label: '150 Days' },
                                { value: 180, label: '180 Days' },
                                { value: 365, label: '365 Days' },
                            ]}
                        />

                        <ReactSelect 
                            formGroup={true}
                            title="How many hours in advance can a person book this event?"
                            description="Example: If set to 3 a person may not book an appointment on this link within 3 hours of opening the link"
                            value={bookingLink.scheduler.min_booking_notice}
                            onChange={(obj) => onNestedInputChange('scheduler.min_booking_notice', obj.value)}
                            options={[
                                { value: '', label: 'Indefinitely' },
                                { value: minutesInHour / 2, label: '30 Minutes' },
                                { value: minutesInHour * 1, label: '1 Hour' },
                                { value: minutesInHour * 2, label: '2 Hours' },
                                { value: minutesInHour * 3, label: '3 Hours' },
                                { value: minutesInHour * 4, label: '4 Hours' },
                                { value: minutesInHour * 5, label: '5 Hours' },
                                { value: minutesInHour * 6, label: '6 Hours' },
                                { value: minutesInHour * 7, label: '7 Hours' },
                                { value: minutesInHour * 8, label: '8 Hours' },
                                { value: minutesInHour * 9, label: '9 Hours' },
                                { value: minutesInHour * 10, label: '10 Hours' },
                                { value: minutesInHour * 11, label: '11 Hours' },
                                { value: minutesInHour * 12, label: '12 Hours' },
                                { value: minutesInHour * 13, label: '13 Hours' },
                                { value: minutesInHour * 14, label: '14 Hours' },
                                { value: minutesInHour * 15, label: '15 Hours' },
                                { value: minutesInHour * 16, label: '16 Hours' },
                                { value: minutesInHour * 17, label: '17 Hours' },
                                { value: minutesInHour * 18, label: '18 Hours' },
                                { value: minutesInHour * 19, label: '19 Hours' },
                                { value: minutesInHour * 20, label: '20 Hours' },
                                { value: minutesInHour * 21, label: '21 Hours' },
                                { value: minutesInHour * 22, label: '22 Hours' },
                                { value: minutesInHour * 23, label: '23 Hours' },
                                { value: minutesInHour * 24, label: '24 Hours' },
                                { value: minutesInHour * 48, label: '2 Days' },
                                { value: minutesInHour * 72, label: '3 Days' },
                            ]}
                        />

                    </div>
                ),
                (
                    <div>

                        <h2>Event Template</h2>

                        <SearchCollections
                            collection={'event_templates'}
                            placeholder=""
                            title="Select Template"
                            value={bookingLink.event_template}
                            onChange={(obj) => onNestedInputChange('event_template', obj.value)}
                            filter={{
                                division: selected_division._id
                            }}
                        /> 

                    </div>
                ),
                (
                    <div>

                        <h2>Lead Tracking</h2>

                        <ReactSelect 
                            formGroup={true}
                            title="Create Lead Upon Submission?"
                            value={bookingLink.create_lead}
                            onChange={(obj) => onNestedInputChange('create_lead', obj.value)}
                            options={[
                                { value: false, label: 'No' },
                                { value: true, label: 'Yes' },
                            ]}
                        />

                        {bookingLink.create_lead ? (
                            <div className="pl-6">
                                <SearchCollections
                                    collection={'lead_sources'}
                                    placeholder=""
                                    title="Select Source"
                                    value={bookingLink.lead_source}
                                    onChange={(obj) => onNestedInputChange('lead_source', obj.value)}
                                    filter={{
                                        division: selected_division._id
                                    }}
                                /> 
                            </div>
                        ) : ''}

                        

                    </div>
                ),
                (
                    <div>

                        <h2>Matter Creation</h2>

                        <ReactSelect 
                            formGroup={true}
                            title="Create Matter Upon Submission?"
                            description="This will not trigger if a current matter is found by the contact who submits the booking entry"
                            value={bookingLink.create_matter}
                            onChange={(obj) => onNestedInputChange('create_matter', obj.value)}
                            options={[
                                { value: false, label: 'No' },
                                { value: true, label: 'Yes' },
                            ]}
                        />

                        {bookingLink.create_matter ? (
                            <div className="pl-6">
                                <SearchCollections
                                    collection={'workflows'}
                                    placeholder=""
                                    title="Select Workflow"
                                    value={bookingLink.matter.workflow}
                                    onChange={(obj) => onNestedInputChange('matter.workflow', obj.value)}
                                    filter={{
                                        division: selected_division._id
                                    }}
                                /> 

                                {bookingLink.matter && bookingLink.matter.workflow ? (
                                    <div className="pl-6">

                                        <SearchCollections
                                            collection={'workflow_contacts'}
                                            placeholder=""
                                            title="Select Booking Client's Party"
                                            value={bookingLink.matter.workflow_contact}
                                            onChange={(obj) => onNestedInputChange('matter.workflow_contact', obj.value)}
                                            filter={{
                                                workflow: bookingLink.matter.workflow
                                            }}
                                        /> 
                                        <SearchCollections
                                            collection={'workflow_roles'}
                                            placeholder=""
                                            title="Select Booking User's Role"
                                            value={bookingLink.matter.workflow_role}
                                            onChange={(obj) => onNestedInputChange('matter.workflow_role', obj.value)}
                                            filter={{
                                                workflow: bookingLink.matter.workflow
                                            }}
                                        /> 
                                    </div>
                                ) : ''}
                            </div>
                        ) : ''}

                        

                    </div>
                ),

            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemBookingLinkEdit);