const USER = [
    'AUTOMATIONS.READ',
    'AUTOMATION_ASSIGNMENTS.READ',
    'AUTOMATION_ASSIGNMENTS.WRITE',
    'BILLING.READ',
    'BILLING.WRITE',
    'CALL_CENTER.READ',
    'CALL_CENTER.WRITE',
    'CALLS.READ',
    'CALL_PHONE_NUMBERS.READ',
    'CALL_FLOWS.READ',
    'COMPANIES.READ',
    'CONTACTS.READ',
    'CONTACTS.WRITE',
    'CONTACT_TYPES.READ',
    'CUSTOM_FIELDS.READ',
    'CUSTOM_VIEWS.READ',
    'DIVISIONS.READ',
    'DOCUMENTS.READ',
    'DOCUMENTS.WRITE',
    'EMAIL_SENDERS.READ',
    'EMAIL_TEMPLATES.READ',
    'EVENTS.READ',
    'EVENTS.WRITE',
    'EVENT_TEMPLATES.READ',
    'EVENT_OUTCOMES.READ',
    'EMAILS.READ',
    'EMAILS.WRITE',
    'FORM_ANSWERS.READ',
    'FORM_SUBMISSIONS.READ',
    'FORMS.READ',
    'LEADS.WRITE',
    'LEADS.READ',
    'LEAD_SOURCES.READ',
    'LOCATIONS.READ',
    'MATTER_DOCUMENTS.READ',
    'MATTERS.READ',
    'MATTERS.WRITE',
    'MATTER_PROGRESS_LOGS.READ',
    'MATTER_DOCUMENTS.WRITE',
    'NOTES.READ',
    'NOTES.WRITE',
    'NOTIFICATIONS.ME',
    'OUTSTANDING_ITEMS.READ',
    'OUTSTANDING_ITEMS.WRITE',
    'PAYMENTS.READ',
    'PAYMENTS.WRITE',
    'PAGE_HISTORIES.READ',
    'PAGE_HISTORIES.WRITE',
    'PIPELINES.READ',
    'SIGNING.EVENTS.READ',
    'SIGNING.DOCUMENTS.READ',
    'SIGNING.DOCUMENTS.WRITE',
    'STRING_TEMPLATES.READ',
    'TEXTS.READ',
    'TEXTS.WRITE',
    'TEXT_TEMPLATES.READ',
    'TIME_ENTRY_TEMPLATES.READ',
    'TASKS.READ',
    'TASKS.WRITE',
    'TAGS.READ',
    'SIGNING.TEMPLATES.READ',
    'SOCIAL_SECURITY_NUMBERS.WRITE',
    'SUPPORT_TICKETS.READ',
    'SUPPORT_TICKETS.WRITE',
    'USERS.READ',
    'USERS.SELF',
    'WIKIS.READ',
    'WORKFLOWS.READ',

    'VIEW.CAN_VIEW_APP_DASHBOARD',
    'VIEW.APP_DASHBOARD.PIPELINE',
    'VIEW.APP_DASHBOARD.MATTERS',
    'VIEW.APP_DASHBOARD.CONTACTS',
    'VIEW.APP_DASHBOARD.LEADS',
    'VIEW.APP_DASHBOARD.TASKS',
    'VIEW.APP_DASHBOARD.OUTSTANDING_ITEMS',
    'VIEW.APP_DASHBOARD.EVENTS',
    'VIEW.APP_DASHBOARD.DOCUMENTS',
    'VIEW.APP_DASHBOARD.TEXTS',
    'VIEW.APP_DASHBOARD.EMAILS',
    'VIEW.APP_DASHBOARD.SUPPORT_TICKETS',
    'VIEW.APP_DASHBOARD.VOICEMAIL',
    'VIEW.APP_DASHBOARD.PHONE_CALLS',
    'VIEW.APP_DASHBOARD.CUSTOM_VIEWS',

    'SECURITY.CAN_VIEW_ALL_MATTERS',
    'SECURITY.CAN_VIEW_ALL_LEAD_SOURCES',
]

const MANAGER = USER.concat([
    'VIEW.CAN_VIEW_MANAGEMENT_DASHBOARD',
    'VIEW.MANAGEMENT_DASHBOARD.TODAYS_ACTIVITY',
    'VIEW.MANAGEMENT_DASHBOARD.TEXT_THREADS',
    'VIEW.MANAGEMENT_DASHBOARD.USER_ACTIVITY',
    'VIEW.MANAGEMENT_DASHBOARD.MONTH_TO_DATE',
    'VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION',
    'VIEW.MANAGEMENT_DASHBOARD.PIPELINE_BREAKDOWN',
    'VIEW.MANAGEMENT_DASHBOARD.REVENUE_PROJECTIONS',
    'VIEW.MANAGEMENT_DASHBOARD.MATTERS_OVERDUE',
    'VIEW.MANAGEMENT_DASHBOARD.OUTSTANDING_ITEMS',
    'VIEW.MANAGEMENT_DASHBOARD.MATTER_ALERTS',
    'VIEW.MANAGEMENT_DASHBOARD.SIGNATURES',
    'VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS',
    'VIEW.MANAGEMENT_DASHBOARD.SUPPORT_TICKETS',
    'VIEW.MANAGEMENT_DASHBOARD.TASKS',
    'VIEW.MANAGEMENT_DASHBOARD.EVENTS',
    'VIEW.MANAGEMENT_DASHBOARD.DOCUMENTS',
    'VIEW.MANAGEMENT_DASHBOARD.VOICEMAIL',
    'VIEW.MANAGEMENT_DASHBOARD.USER_NOTES',
    
    'FUNCTION.JUMP_STEPS',
    'CALLS.WRITE',
    'CONTACTS.PASSWORD'
])

const ADMIN = MANAGER.concat([
    'AUTOMATIONS.WRITE',
    'BACKGROUND_PROCESSES.WRITE',
    'BACKGROUND_PROCESSES.READ',
    'CALL_PHONE_NUMBERS.WRITE',
    'CALL_FLOWS.WRITE',
    'COMPANIES.WRITE',
    'CONTACT_TYPES.WRITE',
    'CUSTOM_FIELDS.WRITE',
    'CUSTOM_VIEWS.WRITE',
    'DIVISIONS.WRITE',
    'EVENT_TEMPLATES.WRITE',
    'EVENT_OUTCOMES.WRITE',
    'EMAIL_SENDERS.WRITE',
    'EMAIL_TEMPLATES.WRITE',
    'EXECUTION_LOGS.READ',
    'EXECUTION_LOGS.WRITE',
    'FORMS.WRITE',
    'LEAD_SOURCES.WRITE',
    'LOCATIONS.WRITE',
    'PIPELINES.WRITE',
    'SIGNING.TEMPLATES.WRITE',
    'STRING_TEMPLATES.WRITE',
    'SOCIAL_SECURITY_NUMBERS.READ',
    'STORED_KEYS.READ',
    'STORED_KEYS.WRITE',
    'TAGS.WRITE',
    'TIME_LOGS.READ',
    'TIME_LOGS.WRITE',
    'TIME_OFF_REQUESTS.WRITE',
    'TIME_OFF_REQUESTS.READ',
    'TEXT_TEMPLATES.WRITE',
    'TIME_ENTRY_TEMPLATES.WRITE',
    'USERS.WRITE',
    'WIKIS.WRITE',
    'WORKFLOWS.WRITE',

    'VIEW.CAN_VIEW_ADMIN_DASHBOARD',
    'VIEW.ADMIN_DASHBOARD.OVERVIEW',
    'VIEW.ADMIN_DASHBOARD.MARKETING',
    'VIEW.ADMIN_DASHBOARD.ANALYZE',
    'VIEW.ADMIN_DASHBOARD.COMMUNICATE',
    'VIEW.ADMIN_DASHBOARD.AUTOMATE',
    'VIEW.ADMIN_DASHBOARD.STAFFING',
    'VIEW.ADMIN_DASHBOARD.SYSTEM',
    'VIEW.ADMIN_DASHBOARD.LAYOUT',
    'VIEW.ADMIN_DASHBOARD.COMPANY',
    'VIEW.ADMIN_DASHBOARD.CUSTOM_VIEWS',
    'VIEW.ADMIN_DASHBOARD.DEVELOPERS',

    'VIEW.CAN_VIEW_AFFILIATE_DASHBOARD',
    'VIEW.AI',
])

const roles = { USER, MANAGER, ADMIN };

export default roles;